import React from "react";
import Layout from "../components/layout";
import Footer from "../components/Footer";

import { graphql, Link } from "gatsby";
import Codepen from "../components/Codepen";
import CKLogo from "../components/Logos/ck";
import WWDLogo from "../components/Logos/wwd";

import ArticleIcon from "../components/icons/article";
import AwardIcon from "../components/icons/award";
import TalkIcon from "../components/icons/talk";
import JobIcon from "../components/icons/job";
import SequoiaLogo from "../components/Logos/sequoia";
import MediumLogo from "../components/Logos/medium";
import ChainLogo from "../components/Logos/chain";
import OptimizelyLogo from "../components/Logos/optimizely";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const iconMap = {
  article: <ArticleIcon size="14" className="mr-2" />,
  award: <AwardIcon size="14" className="mr-2" />,
  talk: <TalkIcon size="14" className="mr-2" />,
  job: <JobIcon size="14" className="mr-2" />,
};

const ProjectLinks = ({ links }) => {
  return (
    <ul className="text-xl grid col-2 tall-row-gap">
      {links.map((link, index) => {
        return (
          <li key={index} className="pb-0 flex">
            <a
              href={link.link}
              className="leading-tight inline-block home-link"
              target="_blank"
            >
              {link.name}
              <span className="metadata flex items-center pt-1 mb-0">
                {iconMap[link.type]} {link.source}
                <span className="px-1">·</span>
                {link.date && <time>{link.date}</time>}
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  const { edges: yaml } = data.allDataYaml;

  return (
    <Layout className="text-white bg-green font-body pt-24 pb-12 px-4 md:px-8">
      <div className="container mx-auto">
        <section>
          <h1 className="headline font-display leading-none pb-0">
            <span
              className="text-peach pb-6 block"
              style={{ fontSize: "0.75em" }}
            >
              ☟
            </span>
            Hi, I’m Jules. I’m a product design director that likes working with
            code.
          </h1>
        </section>
        <hr className="divider" />

        <section>
          <article className="grid col-3">
            <div>
              <a
                href="https://creditkarma.com"
                target="_blank"
                className="bg-peach rounded-sm p-5 inline-block"
              >
                <CKLogo fill="#113134" width="144" />
              </a>
            </div>
            <div style={{ gridColumn: "span 2" }}>
              <h2 className="text-white pt-0 leading-tight">
                Director of Product Design
              </h2>
              <p className="max-w-2xl pb-10">
                I joined{" "}
                <a className="link" href="https://creditkarma.com">
                  Credit Karma
                </a>{" "}
                as design systems lead in 2017 and I now direct our horizontal
                design org, which includes the design systems and product
                recommendations teams. We're responsible for creating internal
                tools that help teams ship high-quality, relevant and accessible
                products, with a focus on native app design and best practices.
              </p>
              <ProjectLinks links={yaml[0].node["Credit_Karma"]} />
            </div>
          </article>
          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <a
                href="https://womenwho.design"
                target="_blank"
                className="bg-peach rounded-sm p-5 inline-block"
              >
                <WWDLogo fill="#113134" width="144" />
              </a>
            </div>

            <div style={{ gridColumn: "span 2" }}>
              <h2 className="text-white pt-0 leading-tight">
                Founder & Developer
              </h2>
              <p className="max-w-2xl pb-10">
                In 2017, I started building{" "}
                <a
                  className="link"
                  href="https://womenwho.design"
                  target="_blank"
                >
                  Women Who Design
                </a>
                , a directory of accomplished women in the design industry.
                Since then, similarly-minded sites like{" "}
                <a href="https://latinxswhodesign.com" target="_blank">
                  Latinxs Who Design
                </a>{" "}
                and{" "}
                <a href="https://blackswho.design" target="_blank">
                  Blacks Who Design
                </a>{" "}
                have launched as well. If you'd like to make your own directory
                for underrepresented folks, you can fork the source code on{" "}
                <a
                  className="link"
                  href="https://github.com/julesforrest/womenwhodesign"
                >
                  GitHub
                </a>
                . You can also support the continued development of the project
                by{" "}
                <a
                  className="link"
                  href="https://womenwhodesign.seeker.company/"
                >
                  posting a job
                </a>
                .
              </p>
              <ProjectLinks links={yaml[0].node["Women_Who_Design"]} />
            </div>
          </article>

          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <h2 className="text-white pt-0 leading-tight">Previously</h2>
            </div>

            <div style={{ gridColumn: "span 2" }} className="grid col-5">
              <a
                href="https://sequoiacap.com"
                target="_blank"
                className="bg-peach rounded-sm inline-flex justify-center items-center px-4 py-5 inline-block"
              >
                <SequoiaLogo fill="#113134" height="20" />
              </a>
              <a
                href="https://chain.com"
                target="_blank"
                className="bg-peach rounded-sm inline-flex justify-center items-center px-4 py-5 inline-block"
              >
                <ChainLogo fill="#113134" height="32" />
              </a>
              <a
                href="https://medium.com"
                target="_blank"
                className="bg-peach rounded-sm inline-flex justify-center items-center px-4 py-5 inline-block"
              >
                <MediumLogo fill="#113134" height="20" />
              </a>
              <a
                href="https://optimizely.com"
                target="_blank"
                className="bg-peach rounded-sm inline-flex justify-center items-center px-4 py-5 inline-block"
              >
                <OptimizelyLogo fill="#113134" height="32" />
              </a>
            </div>
          </article>
          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <h2 className="text-white pt-0 leading-tight">Elsewhere</h2>
            </div>

            <div style={{ gridColumn: "span 2" }}>
              <ProjectLinks links={yaml[0].node["Elsewhere"]} />
            </div>
          </article>
          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <div>
                <h2 className="text-white pt-0 leading-tight">
                  <a
                    href="https://codepen.io/julesforrest"
                    target="_blank"
                    className="home-link"
                  >
                    CodePen
                  </a>
                </h2>
              </div>
            </div>

            <div style={{ gridColumn: "span 2" }}>
              <ul className="grid col-5 ">
                {yaml[0].node.Codepen.map((image, index) => {
                  console.log(image);
                  return (
                    <li key={index} className="pb-0">
                      <a
                        href={image.link}
                        className="block home-link"
                        target="_blank"
                      >
                        <div className=" border border-dashed border-peach p-1 rounded-sm">
                          <GatsbyImage
                            image={
                              image?.image?.childImageSharp?.gatsbyImageData
                            }
                            backgroundColor="#FFCFB8"
                            className="gray no-gray-hover"
                            alt={`Thumbnail of ${image.name}`}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>
          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <div>
                <h2 className="text-white pt-0 leading-tight">
                  <a
                    href="https://dribbble.com/julesforrest"
                    target="_blank"
                    className="home-link"
                  >
                    Dribbble
                  </a>
                </h2>
              </div>
            </div>

            <div style={{ gridColumn: "span 2" }}>
              <ul className="grid col-5 ">
                {yaml[0].node.Dribbble.map((image, index) => {
                  return (
                    <li key={index} className="pb-0">
                      <a
                        href={image.link}
                        className="block home-link"
                        target="_blank"
                      >
                        <div className=" border border-dashed border-peach p-1 rounded-sm">
                          <GatsbyImage
                            image={
                              image?.image?.childImageSharp?.gatsbyImageData
                            }
                            backgroundColor="#FFCFB8"
                            className="gray no-gray-hover"
                            alt={`Thumbnail of ${image.name}`}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>

          <hr className="divider" />
          <article className="grid col-3">
            <div>
              <h2 className="pt-0 leading-tight">
                <Link to="/notes" className="home-link">
                  Notes
                </Link>
              </h2>
            </div>

            <div style={{ gridColumn: "span 2" }}>
              <ul className="text-xl grid col-2 tall-row-gap">
                {posts.map((post, index) => {
                  return (
                    <li key={index} className="pb-0 flex">
                      <Link
                        to={post.node.fields.slug}
                        className="leading-tight inline-block home-link"
                      >
                        {post.node.frontmatter.title}

                        <time className="metadata mb-0 pt-1 ">
                          {post.node.frontmatter.date}
                        </time>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>
        </section>

        <hr className="divider" />
        <Footer />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 8
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            path
          }
        }
      }
    }
    allDataYaml {
      edges {
        node {
          id
          Codepen {
            link
            name
            image {
              id
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  transformOptions: {
                    duotone: { highlight: "#FFCFB8", shadow: "#113134" }
                  }
                )
              }
            }
          }
          Credit_Karma {
            name
            link
            date
            type
            source
          }
          Elsewhere {
            name
            link
            date
            source
            type
          }
          Women_Who_Design {
            date
            link
            name
            source
            type
          }
          Dribbble {
            link
            name
            image {
              id
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  transformOptions: {
                    duotone: { highlight: "#FFCFB8", shadow: "#113134" }
                  }
                )
              }
            }
          }
        }
      }
    }
  }
`;
