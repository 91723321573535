import React from "react";

function SequoiaLogo({ height, width, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 150 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-100 -64)">
          <g>
            <g>
              <g transform="translate(100.5 64)">
                <g transform="translate(.25 .226)">
                  <path d="M52.451 20.106l-1.918-3.322c.905-1.687.893-3.713.893-6.291 0-3.323.026-5.725-1.918-7.67-1.35-1.35-2.997-2.106-5.184-2.106-2.188 0-3.862.756-5.213 2.106-1.944 1.945-1.889 4.347-1.889 7.67 0 3.32-.055 5.723 1.889 7.667 1.351 1.352 3.025 2.107 5.213 2.107.583 0 1.125-.057 1.635-.161h6.492zm-10.558-4.213c-.729-.81-.918-1.701-.918-5.4 0-3.7.189-4.591.918-5.402.54-.62 1.404-1.025 2.431-1.025 1.026 0 1.862.405 2.403 1.025.729.811.945 1.702.945 5.402 0 3.699-.216 4.59-.945 5.4-.541.62-1.377 1.026-2.403 1.026-1.027 0-1.891-.406-2.431-1.026z"></path>
                  <path d="M96.381.879h3.705v19.218h-3.705V.879z"></path>
                  <path d="M7.021 20.267c-2.916 0-5.13-.62-7.021-2.537l2.431-2.431c1.215 1.216 2.863 1.62 4.644 1.62 2.215 0 3.403-.837 3.403-2.376 0-.675-.19-1.243-.595-1.621-.377-.351-.809-.539-1.7-.674l-2.323-.325c-1.648-.242-2.863-.755-3.726-1.592C1.215 9.412.757 8.169.757 6.55.757 3.121 3.295.717 7.453.717c2.646 0 4.564.648 6.211 2.242l-2.377 2.349c-1.214-1.161-2.673-1.323-3.942-1.323-1.997 0-2.971 1.107-2.971 2.43 0 .486.163.999.567 1.377.379.351 1 .648 1.783.756l2.268.324c1.756.243 2.863.729 3.673 1.485 1.025.973 1.485 2.377 1.485 4.078 0 3.725-3.106 5.832-7.129 5.832"></path>
                  <path d="M19.643 20.106V.879h12.665v3.349h-8.912v4.508h7.588v3.35h-7.588v4.671h8.912v3.349H19.643"></path>
                  <path d="M64.161 20.267c-3.942 0-7.075-2.673-7.075-6.751V.879h3.755v12.503c0 2.215 1.295 3.537 3.32 3.537 2.026 0 3.348-1.322 3.348-3.537V.879h3.727v12.637c0 4.078-3.132 6.751-7.075 6.751"></path>
                  <path d="M88.954 18.161c-1.349 1.351-2.998 2.106-5.184 2.106-2.188 0-3.862-.755-5.212-2.106-1.945-1.944-1.891-4.347-1.891-7.668 0-3.322-.054-5.725 1.891-7.67 1.35-1.35 3.024-2.106 5.212-2.106 2.186 0 3.835.756 5.184 2.106 1.944 1.945 1.918 4.348 1.918 7.67 0 3.321.026 5.724-1.918 7.668m-2.781-13.07c-.54-.62-1.378-1.026-2.403-1.026-1.027 0-1.891.406-2.431 1.026-.73.811-.918 1.702-.918 5.402 0 3.698.188 4.59.918 5.4.54.621 1.404 1.026 2.431 1.026 1.025 0 1.863-.405 2.403-1.026.728-.81.944-1.702.944-5.4 0-3.7-.216-4.591-.944-5.402"></path>
                  <path d="M117.764 20.106l-1.136-3.403h-6.831l-1.161 3.403h-3.916L111.714.878h2.943l7.021 19.228h-3.914zM113.28 6.549l-2.43 6.995h4.779l-2.349-6.995z"></path>
                  <path d="M143.699.878h4.808v4.807h-4.808V.878z"></path>
                  <path d="M131.684 16.002V.878h-2.404v17.527l2.404-2.403"></path>
                  <path d="M133.383 17.702l-2.402 2.404h17.526v-2.404h-15.124"></path>
                  <path d="M136.49 11.196V.879h-2.403V13.6l2.403-2.404"></path>
                  <path d="M138.189 12.896l-2.403 2.403h12.72v-2.403h-10.317"></path>
                  <path d="M141.296 6.39V.88h-2.402v7.913l2.402-2.403"></path>
                  <path d="M142.996 8.089l-2.403 2.403h7.913V8.089h-5.51"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SequoiaLogo;
