import React from "react";

function ChainLogo({ height, width, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 125.7 37.2"
      version="1.1"
      viewBox="0 0 125.7 37.2"
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <path
        d="M73.9 29.5h-3.8V5.9h3.8v9.2c1.1-1.4 2.9-2 4.5-2 3.9 0 5.8 2.8 5.8 6.3v10h-3.8V20c0-2-.9-3.5-3.3-3.5-2.1 0-3.2 1.6-3.3 3.6v9.4zM91.7 20.4l4.1-.6c.9-.1 1.2-.6 1.2-1.1 0-1.3-.9-2.4-3-2.4-2 0-3.1 1.3-3.3 2.9l-3.4-.8c.3-2.7 2.8-5.2 6.7-5.2 4.9 0 6.7 2.8 6.7 5.9V27c0 1.4.2 2.4.2 2.5h-3.5c0-.1-.2-.7-.2-2-.7 1.2-2.3 2.5-4.9 2.5-3.3 0-5.4-2.3-5.4-4.8 0-2.8 2.1-4.4 4.8-4.8zm5.2 2.5v-.7l-4.1.6c-1.2.2-2.1.8-2.1 2.1 0 1.1.8 2.1 2.3 2.1 2.1 0 3.9-1 3.9-4.1zM105.7 5.6c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.4-2.5 2.4c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.5 2.4-2.5zm-1.8 23.9V13.7h3.7v15.8h-3.7zM114.7 29.5h-3.8V13.7h3.7v2.1c1-1.8 2.9-2.6 4.7-2.6 3.9 0 5.7 2.8 5.7 6.3v10h-3.8v-9.4c0-2-.9-3.5-3.3-3.5-2.1 0-3.3 1.7-3.3 3.7v9.2zM64.6 21.3c-1 2.6-3.1 4.9-7 4.9S50 23.3 50 17.9c0-5.6 3.8-8.4 7.6-8.4 3.6 0 6 2.1 6.9 4.6l3.2-1.8c-1.3-3.6-4.7-6.4-10-6.4-6 0-11.6 4.5-11.6 12s5.4 12 11.7 12c5.5 0 8.9-3.2 10.2-6.8l-3.4-1.8z"
        fill={fill}
      ></path>
      <path
        d="M32.5 11.1L28.6 13.4 33.9 16.5 33.9 27.2 24.6 32.6 15.3 27.2 15.3 16.5 20.6 13.4 19.9 12.9 16.7 11.1 11.3 14.2 11.3 29.5 24.6 37.2 37.9 29.5 37.9 14.2z"
        fill={fill}
      ></path>
      <path
        d="M13.3 0L0 7.7 0 23 5.4 26.1 8.6 24.2 9.3 23.8 4 20.7 4 10 13.3 4.6 22.6 10 22.6 20.7 17.3 23.8 21.2 26.1 26.6 23 26.6 7.7z"
        fill={fill}
      ></path>
    </svg>
  );
}

export default ChainLogo;
